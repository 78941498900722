const DEFAULT_FONT_SIZE = "14pt";

function renderBullet(group, key) {
  if (group.bulletType === "bullet") {
    return (
      <ul
        className={"tb_list"}
        style={{ listStylePosition: "outside", listStyleType: group.curType }}
      >
        {group.lines.map((line) => {
          const firstElement = line.characters[0];
          const { style } = line
          const  style2  = firstElement?.style || {
            style: {
              color: "",
              fontSize: DEFAULT_FONT_SIZE,
            },
          };
          return (
            <li
              key={key}
              className={"marker"}
              style={{
                ...style,
                color: style2?.color ?? "",
                fontSize: style2?.fontSize ?? DEFAULT_FONT_SIZE,
              }}
            >
              {line.characters.map((character, index) => {
                if (character.sub) {
                  return (
                    <sub key={key + "_" + index} style={character.style}>
                      {character.char === "\n" ? <br /> : character.char}
                    </sub>
                  );
                } else if (character.sup) {
                  return (
                    <sup key={key + "_" + index} style={character.style}>
                      {character.char === "\n" ? <br /> : character.char}
                    </sup>
                  );
                } else if (character.href) {
                  return (
                    <a
                      href={character.href}
                      target={"_blank"}
                      key={key + "_" + index}
                      style={character.style}
                      rel="noreferrer"
                    >
                      {character.char === "\n" ? <br /> : character.char}
                    </a>
                  );
                } else {
                  return (
                    <span key={key + "_" + index} style={character.style}>
                      {character.char === "\n" ? <br /> : character.char}
                    </span>
                  );
                }
              })}
            </li>
          );
        })}
      </ul>
    );
  } else {
    return (
      <ol
        className={"tb_list"}
        style={{ listStylePosition: "outside", listStyleType: group.curType }}
      >
        {group.lines.map((line) => {
          const firstElement = line.characters[0];
          const { style } = firstElement || {
            style: {
              color: "",
              fontSize: DEFAULT_FONT_SIZE,
            },
          };
          return (
            <li
              key={key}
              style={{
                ...line.style,
                marginLeft:
                  (line.style.marginLeft &&
                    line.style.marginLeft === "0px" &&
                    group.curType === "decimal-leading-zero") ||
                  (!line.style.marginLeft && group.curType === "decimal-leading-zero")
                    ? "20px"
                    : !line.style.marginLeft
                      ? "0px"
                      : line.style.marginLeft,
                color: style.color,
                fontSize: style.fontSize,
              }}
            >
              {line.characters.map((character, index) => {
                if (character.sub) {
                  return (
                    <sub key={key + "_" + index} style={character.style}>
                      {character.char === "\n" ? <br /> : character.char}
                    </sub>
                  );
                } else if (character.sup) {
                  return (
                    <sup key={key + "_" + index} style={character.style}>
                      {character.char === "\n" ? <br /> : character.char}
                    </sup>
                  );
                } else if (character.href) {
                  return (
                    <a
                      href={character.href}
                      target={"_blank"}
                      key={key + "_" + index}
                      style={character.style}
                      rel="noreferrer"
                    >
                      {character.char === "\n" ? <br /> : character.char}
                    </a>
                  );
                } else {
                  return (
                    <span key={key + "_" + index} style={character.style}>
                      {character.char === "\n" ? <br /> : character.char}
                    </span>
                  );
                }
              })}
            </li>
          );
        })}
      </ol>
    );
  }
}

function renderLine(line, key) {
  if (line.characters.length > 0) {
    return (
      <p
        key={key}
        style={{
          ...line.style,
          marginBlockStart: "unset",
          marginBlockEnd: "unset",
          height: "auto",
          fontSize: "none",
        }}
      >
        {line.characters.map((character, index) => {
          if (character.char) {
            if (character.sub) {
              return (
                <sub key={key + "_" + index} style={character.style}>
                  {character.char === "\n" ? <br /> : character.char}
                </sub>
              );
            } else if (character.sup) {
              return (
                <sup key={key + "_" + index} style={character.style}>
                  {character.char === "\n" ? <br /> : character.char}
                </sup>
              );
            } else if (character.href) {
              return (
                <a
                  href={character.href}
                  target={"_blank"}
                  key={key + "_" + index}
                  style={character.style}
                  rel="noreferrer"
                >
                  {character.char === "\n" ? <br /> : character.char}
                </a>
              );
            } else {
              return (
                <span key={key + "_" + index} style={character.style}>
                  {character.char === "\n" ? <br /> : character.char}
                </span>
              );
            }
          }
        })}
      </p>
    );
  } else {
    return (
      <p key={key} style={{ ...line.style, margin: 0, height: "auto" }}>
        <span>
          <br />
        </span>
      </p>
    );
  }
}

function renderLines(lines) {
  const newLines = [];
  let group = null;
  lines.forEach((line) => {
    if (line.bullet) {
      if (group === null || group.key !== line.key || group.curType !== line.curType) {
        group = {
          key: line.key,
          lines: [],
          bullet: true,
          bulletType: line.bulletType,
          curType: line.curType,
        };
        newLines.push(group);
      }

      group.lines.push(line);
    } else {
      newLines.push(line);
    }
  });

  return newLines.map((c, index) =>
    c.bullet ? renderBullet(c, index) : renderLine(c, "key_ " + index),
  );
}

export { renderLines };
