import React, { useState } from "react";
import "./loginInput.scss";
import ButtonMui from "../../buttons/buttonMui/buttonMui";
import { EyeIcon, EyeIconClose } from "../../icons/svgIcons";

const LoginInput = ({
  label,
  errorMessage,
  type,
  placeholder,
  value,
  onChange,
  name,
  onKeyDown,
  errorTip,
  hideLabel,
  disabled,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      {!hideLabel && <label className="input-label">{label}</label>}
      <div className={"input-end-adornment-container"}>
        <input
          disabled={disabled}
          value={value}
          onKeyDown={onKeyDown}
          name={name}
          onChange={onChange}
          type={showPassword ? "text" : type}
          className={`text-input${errorMessage?.length ? " error" : ""}${
            type === "password" ? " password" : ""
          }`}
          placeholder={placeholder}
        />
        {type === "password" && (
          <ButtonMui
            variant={"text"}
            color={"black"}
            onClick={() => setShowPassword(!showPassword)}
            className={"input-adornment"}
            isIconButton
            label={!showPassword ? <EyeIcon /> : <EyeIconClose />}
            style={{ height: "30px" }}
            tabIndex={"-1"}
          />
        )}
      </div>
      {errorMessage && errorMessage.length && (
        <div className={"input-err-message"}>{errorMessage}</div>
      )}
      {errorTip && errorTip.length && <div className={"input-err-tip"}>{errorTip}</div>}
    </>
  );
};

export default LoginInput;
