import React, { Component } from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IconButton, Fab, Button, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

const variantTypes = {
  text: "text",
  contained: "contained",
  outlined: "outlined",
};

const buttonRoundnessTypes = {
  rounded: "rounded",
  squared: "squared",
};

export const button_theme = createTheme({
  palette: {
    primary: {
      main: "#01AFFD",
      contrastText: "#fff",
    },
    black: {
      main: "#000000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#0080BF",
      contrastText: "#fff",
    },
    red: {
      main: "#EA0000",
      contrastText: "#fff",
    },
    grey: {
      main: "#D6D6D6",
      contrastText: "#fff",
    },
    dark_grey: {
      main: "#878787",
      contrastText: "#fff",
    },
  },
});

const getBorderRadius = (roundnessType = buttonRoundnessTypes.rounded) => {
  if (roundnessType === buttonRoundnessTypes.rounded) {
    return 20;
  }
  return 1;
};

const getStyle = (style = {}) => {
  return style;
};

const getColorKey = (color = "primary") => {
  return color;
};

const getFontSize = (fontSize = 14) => {
  return fontSize;
};

const getWidth = (width = "fit-content") => {
  return width;
};

const getVariant = (variant = variantTypes.outlined) => {
  return variant;
};

function hexToRgba(hex, opacity = 1) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})`
    : hex;
}

const TooltipWrapper = (props) => {
  return (
    <Tooltip
      arrow
      disableHoverListener={props.disableHoverListener}
      enterNextDelay={500}
      enterDelay={500}
      title={props.title}
    >
      <span>{props.children}</span>
    </Tooltip>
  );
};

class ButtonMui extends Component {
  styles = {
    [variantTypes.text]: {
      "&:hover": {
        background: hexToRgba(button_theme.palette[getColorKey(this.props.color)].main, 0.13),
      },
    },
    [variantTypes.contained]: {
      border: `1px solid ${button_theme.palette[getColorKey(this.props.color)].main}`,
      "&:hover": {
        background: "#fff",
        color: "#000",
      },
      ".MuiTouchRipple-child": {
        backgroundColor: `${button_theme.palette[getColorKey(this.props.color)].main} !important`,
      },
      "&.Mui-disabled": {
        border: "unset",
        color: "rgba(0, 0, 0, 0.26)",
      },
    },
    [variantTypes.outlined]: {
      color: "#000",
      "&:hover": {
        background: button_theme.palette[getColorKey(this.props.color)].main,
        color: "#fff",
      },
      ".MuiTouchRipple-child": {
        backgroundColor: `#fff`,
      },
    },
  };

  constructor(props) {
    super(props);
  }

  getBtnComponent = () => {
    if (this.props.linkTo) {
      return Link;
    }
  };

  getIcon = (prefix) => {
    if (!this.props.isIconButton) {
      return {
        [prefix + "Icon"]: this.props[prefix + "Icon"],
      };
    }
  };

  getButton = (btnProps) => {
    if (this.props.isIconButton) {
      return <IconButton {...btnProps}>{this.props.label}</IconButton>;
    }
    if (this.props.isFloatingButton) {
      const { endIcon, startIcon, ...otherProps } = btnProps;
      const renamedProps = {
        ...otherProps,
        endicon: endIcon,
        starticon: startIcon,
      };
      return <Fab {...renamedProps}>{this.props.label}</Fab>;
    }
    return <Button {...btnProps}>{this.props.label}</Button>;
  };

  render = () => {
    const btnProps = {
      component: this.getBtnComponent(),
      color: getColorKey(this.props.color),
      to: this.props.linkTo,
      onMouseDown: this.props.onMouseDown,
      sx: {
        borderRadius: getBorderRadius(this.props.roundnessType),
        width: getWidth(this.props.width),
        height: "36px",
        whiteSpace: "nowrap",
        lineHeight: "unset !important",
        textTransform: "unset",
        fontWeight: 500,
        fontFamily: "Inter",
        boxSizing: "border-box",
        fontSize: getFontSize(this.props.fontSize),
        textAlign: "center",
        ...this.styles[getVariant(this.props.variant)],
        ...getStyle(this.props.style),
        boxShadow: "unset",
      },
      variant: getVariant(this.props.variant),
      onClick: this.props.onClick,
      ref: this.props.innerRef,
      ...this.getIcon("end"),
      ...this.getIcon("start"),
      disabled: this.props.disabled,
      className: this.props.className,
      ...this.props.additionalProps,
      tabIndex: this.props.tabIndex,
    };

    const button = this.getButton(btnProps);

    const themedButton = <ThemeProvider theme={button_theme}>{button}</ThemeProvider>;

    return this.props.tooltipText ? (
      <TooltipWrapper disableHoverListener={this.props.disableEdit} title={this.props.tooltipText}>
        {themedButton}
      </TooltipWrapper>
    ) : (
      themedButton
    );
  };
}

ButtonMui.propTypes = {
  label: PropTypes.any.isRequired,
  startIcon: PropTypes.element,
  disabled: PropTypes.bool,
  endIcon: PropTypes.element,
  theme_color_key: PropTypes.string,
  outlined: PropTypes.bool,
  style: PropTypes.object,
  variant: PropTypes.oneOf([variantTypes.text, variantTypes.contained, variantTypes.outlined])
    .isRequired,
  color: PropTypes.oneOf(["primary", "secondary", "purple", "red", "black", "grey", "dark_grey"]),
  width: PropTypes.any,
  fontSize: PropTypes.number,
  roundnessType: PropTypes.oneOf([buttonRoundnessTypes.rounded, buttonRoundnessTypes.squared]),
  isIconButton: PropTypes.bool,
  isFloatingButton: PropTypes.bool,
  tooltipText: PropTypes.string,
  className: PropTypes.string,
  linkTo: PropTypes.string,
  additionalProps: PropTypes.object,
  tabIndex: PropTypes.number,
};

ButtonMui.defaultProps = {
  tabIndex: 0,
};

export default React.forwardRef((props, ref) => <ButtonMui innerRef={ref} {...props} />);
