import React, { Component } from "react";
import PropTypes from "prop-types";
import SlideComponent from "../SlideComponent";
import request from "superagent";
import "./presentation.scss";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";

class Presentation extends Component {
  currentElement = null;
  constructor(props) {
    super(props);

    this.getTemplates();

    this.state = {
      hasTemplates: false,
      loading: true,
    };

    this.mount = false;

    this.selectedIndex = this.props.selectedSectionIndex ? this.props.selectedSectionIndex : 0;
    this.templates = Array(this.props.slideSectionsLength).fill(null);
    this.templates[this.selectedIndex] = this.props.currentTemplate;

    this.key = `presentation_key_${new Date().getTime()}`;

    this.idsObject = {};
  }

  getUniqueId = (index) => {
    const id = `sp_f_s_${new Date().getTime()}_${index}`;
    this.idsObject[index] = id;
    return id;
  };

  onFullScreenChange = (e) => {
    if (!document.fullscreenElement) {
      this.props.onPreviewExit();
    }
  };

  getTemplates = () => {
    request
      .get("/api/printTemplates")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .query({
        company_id: global.Modeliks.CompanyInfo.ID,
        pitch_scenario_id: global.Modeliks.PitchScenarioInfo.ID,
      })
      .then((data) => {
        this.backgroundColors = data.body.splice(0, 1)[0];
        this.templates = [...data.body];
        this.selectedIndex = this.props.selectedSectionIndex;
        this.key = "presentation_key_" + new Date().getTime();
        this.setState({ loading: false });
      });
  };

  handleFullScreen = () => {
    const element = document.getElementById(this.idsObject[this.selectedIndex]);
    element.requestFullscreen();
    if (this.currentElement && this.currentElement.exitFullscreen) {
      this.currentElement.exitFullscreen();
    }
    this.currentElement = element;
  };

  changeSlides = (value) => {
    if (this.templates[value + this.selectedIndex]) {
      this.selectedIndex += value;
      this.handleFullScreen();
    }
  };

  handleKeyDown = (event) => {
    switch (event.key) {
      case "ArrowLeft":
        this.changeSlides(-1);
        break;
      case "ArrowRight":
        this.changeSlides(1);
        break;
      case "Escape":
        this.props.onPreviewExit && this.props.onPreviewExit();
        break;
      default:
        return;
    }
  };

  handleMount = () => {
    if (!this.mount) {
      this.mount = true;
      document.addEventListener("keydown", this.handleKeyDown);
      document.addEventListener("fullscreenchange", this.onFullScreenChange);
      this.handleFullScreen();
    }
  };
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("fullscreenchange", this.onFullScreenChange);
  }

  render() {
    return (

        <div className={"presentation_container"}>
          {this.templates.map((template, index) => (
            <SlideComponent
              disableEdit
              showLogo={index > 0}
              onMount={this.handleMount}
              PitchScenarioInfo={global.Modeliks.PitchScenarioInfo}
              selectedTemplateColor={this.backgroundColors && this.backgroundColors[index]}
              disableHeader
              scale={{}}
              uniqueId={this.getUniqueId(index)}
              jsonData={template}
              key={index === this.selectedIndex ? index : `sc_${new Date().getTime()}_${index}`}
            >
              <div className={"buttons_wrapper"}>
                <div className={"buttons_container"}>
                  <ButtonMui width={100} label={"Previous"} onClick={() => this.changeSlides(-1)} />
                  <ButtonMui width={100} label={"Next"} onClick={() => this.changeSlides(1)} />
                </div>
                <div className="p_c_pagging">
                  {index + 1} / {this.templates.length}
                </div>
              </div>
            </SlideComponent>
          ))}
        </div>
    );
  }
}

Presentation.propTypes = {
  onPreviewExit: PropTypes.func,
};

export default Presentation;
